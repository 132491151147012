// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-consulte-js": () => import("./../../../src/pages/consulte.js" /* webpackChunkName: "component---src-pages-consulte-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-evaluate-js": () => import("./../../../src/pages/evaluate.js" /* webpackChunkName: "component---src-pages-evaluate-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-share-js": () => import("./../../../src/pages/share.js" /* webpackChunkName: "component---src-pages-share-js" */),
  "component---src-pages-tutorial-js": () => import("./../../../src/pages/tutorial.js" /* webpackChunkName: "component---src-pages-tutorial-js" */),
  "component---src-templates-template-category-js": () => import("./../../../src/templates/template-category.js" /* webpackChunkName: "component---src-templates-template-category-js" */),
  "component---src-templates-template-docs-js": () => import("./../../../src/templates/template-docs.js" /* webpackChunkName: "component---src-templates-template-docs-js" */)
}

